@import "~@de-electron/design-tokens/dist/web/__all";
@import "~@de-electron/component-library/src/global/scss/base/_loader";
@import "~@de-electron/component-library/src/global/scss/base/_reset";
@import "~@de-electron/component-library/src/global/scss/base/_typography";


// material specific section
@import "~@angular/material/theming";
@import "./app/styles/material-palettes";
@include mat-core();
$primary: mat-palette($duke-primary);
$accent: mat-palette($duke-accent);
$warn: mat-palette($duke-warn);
$theme: mat-light-theme($primary, $accent, $warn);
$custom-typography: mat-typography-config(
  $font-family: font-family("NewsGothicBT-Roman"),
);
@include mat-core($custom-typography);
@include angular-material-theme($theme);
@import "./app/styles/material-overrides";
*{
  font-family: "NewsGothicBT-Roman", Arial, sans-serif !important;
}


.main-container {
  // @include spacing-offset(padding, l, zero);
  display: block;
  min-height: calc(100vh - (96px + 122px)); /* will cover the 100% of viewport */
  overflow: hidden;
  position: relative;
  @include breakpoint(m) {
    min-height: calc(100vh - (96px + 164px)); /* will cover the 100% of viewport */
  }
  @include breakpoint(l) {
    // @include spacing-offset(padding, 2xl, zero);
    min-height: calc(100vh - (96px + 96px)); /* will cover the 100% of viewport */
  }
}

.radio-button-wrapper {
  border-top: 1px solid color(gray, light);
  border-right: 1px solid color(gray, light);
  border-left: 1px solid color(gray, light);
  &:last-of-type {
    border-bottom: 1px solid color(gray, light);
  }
}

.modal-body {
  max-width: 300px;
  @include spacing-equal(padding, m);
  text-align: center;
  @include breakpoint(m) {
    @include spacing-equal(padding, l);
    max-width: 400px;
  }
  @include breakpoint(l) {
    @include spacing-equal(padding, 2xl);
    max-width: inherit;
  }
}

// for each page
.page-container {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mat-card {
  border-radius: 0px !important;
  margin-top: 5px;
  margin-bottom: 5px;
}

// mat-table
.gray { background-color: #f5f5f5 }

.mat-header-cell {
  font-size: 15px;
  font-weight: 600;
  color:#014464 !important;
}

// icon
.icon-size-2x {
  transform: scale(1.2);
}

// for link
a.internal-link {
  font-weight: 600;
  color: #00789e;
  text-decoration: none;
}
a.internal-link:hover {
  text-decoration: underline;
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  opacity: 0.2 !important;
}
// ------------------------------------
// Table Styling
// ------------------------------------
.table-row {
  cursor: pointer;
}

.table-row:focus {
  background: rgb(243,251,253);
  outline: none;
}
.table-row:focus mat-cell {
  font-size: 14px;
  font-weight: bolder;
}
.mat-footer-cell {
  font-weight: bold;
  font-size: 18px;
  background-color: rgb(243,251,253);
}

// snackbar
.green-snackbar {
  background: #00853f;
  color: white;
}
.green-snackbar button {
  background-color: #00853f;
  color: white;
  border: none;
}
.red-snackbar {
  background: #9e1b32;
  color: white;
}
.red-snackbar button {
  background-color: #9e1b32;
  color: white !important;
  border: none;
}
.red-snackbar-leads {
  background: #9e1b32;
  color: white;
  position: relative;
  top: 19rem;
  z-index: 10;
}
.red-snackbar-leads button {
  background-color: #9e1b32;
  color: white !important;
  border: none;
}


// Duke Standard colors

// White
// #ffffff
// rgb: 255,255,255
// Blue
// #005984
// rgb: 0,89,132
// Gray-dark
// #696969
// rgb: 105,105,105
// Teal-dark-x
// #067799
// rgb: 6,119,153
// Gray-light-x
// #f2f4f4
// rgb: 242,244,244
// Gray-light
// #dfe4e4
// rgb: 223,228,228
// Teal-light-x
// #f3fbfd
// rgb: 243,251,253
// Gray
// #999999
// rgb: 153, 153, 153
// Teal-light
// #e0f6fb
// rgb: 224,246,251
// Teal
// #a7e1ea
// rgb: 167,225,234
// Blue-dark
// #014464
// rgb: 1,68,100
// Red
// #9e1b32
// rgb: 158,27,50
// Green
// #54b948
// rgb: 84,185,72
// Green-dark
// #00853f
// rgb: 0,133,63
// Orange
// #f78e1e
// rgb: 247,142,30
// Yellow
// #ffd200
// rgb: 255,210,0
// Tan
// #e7d2ad
// rgb: 231,210,173
// Cream
// #ffeebb
// rgb: 255,238,187
// Teal-dark
// #26bcd7
// rgb: 38,188,215
// Gray-dark-x
// #4c4c4c
// rgb: 76,76,76

  // /* Small (sm) */
  // @media (min-width: 640px) { /* ... */ }

  // /* Medium (md) */
  // @media (min-width: 768px) { /* ... */ }

  // /* Large (lg) */
  // @media (min-width: 1024px) { /* ... */ }

  // /* Extra Large (xl) */
  // @media (min-width: 1280px) { /* ... */ }
