@mixin error {
  &.error{
    .mat-form-field-infix{
      @include spacing-offset(padding, m, xs);
      border: 1px solid color(red)
       }
    
       .mat-form-field-label{
        color: color(red)
      }
  }
}



.dpa-input {
    @include spacing-unique(margin, xl, zero, zero, zero);
     .mat-form-field-infix{
    @include spacing-offset(padding, m, xs);
    border: 1px solid color(teal, darker);
    display:flex;
     }
  
     .mat-form-field-label{
      @include spacing-unique(margin, zero, zero, s, zero);
      color: color(teal, darker);
      top:1em;
    }
    .mat-form-field-label-wrapper{
      overflow:visible;
    }
    .mat-form-field-ripple{
      display:none;
    }
    @include error;
  }



.agency-input {
     .mat-form-field-infix{
    @include spacing-offset(padding, m, xs);
    border: 1px solid color(teal, darker);
    display:flex;
     }
  
     .mat-form-field-label{
      @include spacing-unique(margin, zero, zero, s, zero);
      color: color(teal, darker);
      top:1em;
    }
    .mat-form-field-label-wrapper{
      overflow:visible;
    }
    .mat-form-field-ripple{
      display:none;
    }
    @include error;
   
  }
  
  
  
  .success-snackbar.mat-snack-bar-container{
    background-color: color(green, dark);
    box-shadow: none;
    border-radius: 0px 0px 0px 0px;
  }